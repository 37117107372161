.tradeButton {
  height: 4em;
  width: 8em;
  margin: 1em;
  border-style: none;
  border-radius: 10px;
  color: white;
  font-size: 1em;
}

input {
  width: 20em;
  height: 2em;
  text-align: center;
  margin: 1em;
  border-style: none;
  border-radius: 10px;
  background-color: rgb(241, 241, 241);
}

.inputFormContainer {
  width: 20em;
  right: 0;
  left: 0;
  position: absolute;
  margin: 0 auto;
}

.tradeButtonContainer {
  position: absolute;
  right: 0;
  left: 0;
  top: 10em;
  margin: 0 auto;
  width: 20em;
}

#long {
  background-color: rgb(48, 172, 48);
}

#short {
  background-color: rgb(255, 81, 81);
}
